@mixin commonCard {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 12px 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 12px 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
}

.widget {
  .text-blue {
    color: #307baa82;
  }

  .text-orange {
    color: #ea5a0c82;
  }

  .text-green {
    color: #16a34a82;
  }

  .borders-b {
    margin-bottom: 20px;
    border-bottom: 5px solid #307baa82;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
  }

  .borders-b:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }

  .borders-o {
    margin-bottom: 20px;
    border-bottom: 5px solid #ea5a0c82;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
  }

  .borders-o:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }

  .borders-g {
    margin-bottom: 20px;
    border-bottom: 5px solid #16a34a82;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
  }

  .borders-g:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }
}

.bigwidget {
  .bg-dark-blue {
    background-color: #313447;
    color: #fff;
  }

  .text-blue {
    color: #307baa82;
  }

  .text-light-blue {
    color: #4fc3f7;
  }

  .text-orange {
    color: #ea5a0c82;
  }

  .text-green {
    color: #16a34a82;
  }

  .borders-b {
    margin-bottom: 20px;
    border-bottom: 5px solid #4fc3f7;
    border-radius: 8px;

    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
  }

  .borders-o {
    margin-bottom: 20px;
    border-bottom: 5px solid #ea5a0c82;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
  }

  .borders-g {
    margin-bottom: 20px;
    border-bottom: 5px solid #16a34a82;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
  }
}

.borders-b {
  margin-bottom: 20px;
  border-bottom: 5px solid #4fc3f7;
  border-radius: 8px;

  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
}

.nodes-card {
  @include commonCard;
  color: #212121;

  .title-2 {
    font-size: 16px;

    color: #1C1C1C;

    .node-title {
      font-weight: 600;
      font-size: 16px;
      color: #1C1C1C;
    }

    .node-subtext {
      font-size: 16px;
      font-weight: 500;
      word-break: break-all;

    }
  }
}