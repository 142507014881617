 .stepper-wrapper {
     width: 100%;
     padding: 16px;

     .stepper {
         display: flex;
         justify-content: space-between;

         .stepper-item {
             position: relative;
             display: flex;
             flex-direction: column;
             align-items: center;
             flex: 1;
             min-width: 52px;
         }

         .step {
             display: flex;
             flex-direction: column;
             align-items: center;
         }

         .stepper-item:nth-last-child(2) {
             &::after {
                 left: 50% !important;
                 width: 150% !important;
             }
         }

         .stepper-item:first-child {
             align-items: start;

             &::after {
                 width: 150% !important;
                 left: 0% !important;
             }

             .step-counter-wrapper {
                 width: 100%;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 background: linear-gradient(to right,
                        #ffffff 50%,
                         transparent 50%);
                 z-index: 4;
             }
         }

         .stepper-item:first-child.stepper-item:nth-last-child(2) {
             &::after {
                 left: 0% !important;
                 width: 200% !important;
             }
         }

         .stepper-item:last-child {
             align-items: end;

             .step-counter-wrapper {
                 width: 100%;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 background: linear-gradient(to right,
                         transparent 50%,
                        #ffffff 50%);
                 z-index: 4;
             }
         }

         .stepper-item.inactive::after {
             position: absolute;
             content: "";
             border-bottom: 2px solid #E2E4EA;
             width: 100%;
             top: 12px;
             left: 50%;
             z-index: 2;
         }

         .stepper-item .step-counter {
             position: relative;
             z-index: 5;
             display: flex;
             justify-content: center;
             align-items: center;
             width: 24px;
             height: 24px;
             border-radius: 50%;
             background: #E2E4EA;
             margin-bottom: 6px;
             font-style: normal;
             font-weight: 400;
             font-size: 12px;
             letter-spacing: 0.4px;
             color: #393A4A;
         }

         .stepper-item .stepper-text {
             font-style: normal;
             font-weight: 300;
             font-size: 12px;
             letter-spacing: 0.4px;
             color: #393A4A;
             text-align: center;
             word-break: break-word;
         }

         .stepper-item.active {
             font-style: normal;
             font-weight: 600;
             font-size: 12px;
             letter-spacing: 0.4px;
             color: #1C1C24;
             text-align: center;
         }

         .stepper-item.active .step-counter {
             background-color: #4398CD;
             color:#ffffff;
         }

         .stepper-item.active .stepper-text {
             color: #1C1C24;
             font-weight: 600;
         }

         .stepper-item.active .step-counter::before {
             content: "";
             top: 0px;
             left: 0px;
             right: 0px;
             bottom: 0px;
             outline: white 4px solid;
             position: absolute;
             border-radius: 24px;
         }

         .stepper-item.active .step-counter::after {
             content: "";
             top: -4px;
             left: -4px;
             right: -4px;
             bottom: -4px;
             outline: #4398CD 2px solid;
             position: absolute;
             border-radius: 24px;
         }

         .stepper-item.active::after {
             position: absolute;
             content: "";
             border-bottom-width: 2px;
             border-bottom-style: solid;
             border-image: linear-gradient(to right,
                     #4398CD 50%,
                     #E2E4EA 50%) 1;
             width: 100%;
             top: 12px;
             left: 50%;
             z-index: 3;
         }

         .stepper-item.completed .step-counter {
             background-color: #16A34A;
             background-image: url("../../images/tick.svg");
             background-repeat: no-repeat;
             background-position: center;
         }

         .stepper-item.completed .stepper-text {
             font-weight: 400;
             color: #30313F;
         }

         .stepper-item.completed::after {
             position: absolute;
             content: "";
             border-bottom: 2px solid #16A34A;
             width: 100%;
             top: 12px;
             left: 50%;
             z-index: 3;
         }

         .stepper-item:first-child::before {
             content: none;
         }

         .stepper-item:last-child::after {
             content: none;
         }
     }
 }