@mixin toastContainer {
    box-shadow: 0px 0px 16px rgba(174, 174, 187, 0.25), 0px 2px 2px rgba(0, 0, 0, 0.0124);
    border-radius: 8px;
    margin-bottom: 8px;
    padding: unset;
    min-height: 38px;
    display: flex;
}

@mixin toastBody {
    padding: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.25px;
    display: flex;
    gap: 8px;
}

.dsm__Toastify__toast-container {
    width: -moz-fit-content;
    width: fit-content !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    .Toastify__toast--success {
        @include toastContainer;
        background: #F0FDF4;
        border: 1px solid #16A34A;


        .Toastify__toast-body {
            @include toastBody;
            color: #16A34A;
        }
    }

    .Toastify__toast--error {
        @include toastContainer;
        background: #FEF2F2;
        border: 1px solid #DC2626;

        .Toastify__toast-body {
            @include toastBody;
            color: #DC2626;
        }
    }

    .Toastify__toast--info {
        @include toastContainer;
        background: #EFF6FF;
        border: 1px solid #2563EB;

        .Toastify__toast-body {
            @include toastBody;
            color: #2563EB;
        }
    }

    .Toastify__toast--warning {
        @include toastContainer;
        background: #FFF7ED;
        border: 1px solid #EA580C;


        .Toastify__toast-body {
            @include toastBody;
            color: #EA580C;
        }
    }

    .Toastify__close-button {
        align-self: center;
        padding: 8px;
        background: transparent;
        border: none;
        position: relative;
        bottom: 2px;

        svg {
            fill: currentColor;
            height: 12px;
            width: 12px;
        }
    }

    .custom-content {
        display: flex;
        align-items: center;
        gap: 24px;
    }
}