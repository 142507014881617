.documentation-main {
    padding: 0px 24px;

    section {
        margin-bottom: 40px;

        .title {
            color: #12263C;
            font-size: 28px;
            font-weight: 500;
            letter-spacing: 0.75px;
            margin-bottom: 16px;
        }

        .description {
            color: #12263C;
            font-size: 16px;
            letter-spacing: 0.4px;
            font-weight: 400;

            sup {
                font-size: 10px;
                position: relative;
                top: -8px;
            }

            ul {
                padding-left: 0px;
                list-style: none;
            }
        }

        .note-card {
            border-radius: 8px;
            background-color: #ffffff;
            padding: 20px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 16px;
            color: #212121;
            gap: 16px;
            border-left: 6px solid;
            border-color: #3D97CF;

            .header {
                color: #12263C;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0.5px;
                padding-bottom: 12px;
                border-bottom: 1px solid;
                margin-bottom: 12px;

                img {
                    position: relative;
                    right: 4px;
                    bottom: 2px;
                }
            }

            .description {
                color: #12263C;
                font-size: 15px;
                letter-spacing: 0.4px;
                font-weight: 400;

                sup {
                    font-size: 10px;
                    position: relative;
                    top: -8px;
                }
            }

            ul {
                margin-top: 8px;

                li {
                    color: #3D97CF;
                    margin: 0;
                    font-size: 15px;
                    letter-spacing: 0.4px;
                    cursor: pointer;
                    transition: ease-in-out .2s;
                    font-weight: 500;
                    margin-bottom: 4px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.news-feed-body {
    .no-news-available {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 50px;
    }
}