// main.scss (or _mixins.scss if you prefer separating concerns)

// Breakpoint variables
$breakpoint-xsm: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Mixin using variables
@mixin respond-to($breakpoint) {
    @if $breakpoint==xsm {
        @media (max-width: #{$breakpoint-xsm}) {
            @content;
        }
    }

    @if $breakpoint==sm {
        @media (max-width: #{$breakpoint-sm}) {
            @content;
        }
    }

    @else if $breakpoint==md {
        @media (max-width: #{$breakpoint-md}) {
            @content;
        }
    }

    @else if $breakpoint==lg {
        @media (max-width: #{$breakpoint-lg}) {
            @content;
        }
    }

    @else if $breakpoint==xl {
        @media (max-width: #{$breakpoint-xl}) {
            @content;
        }
    }
}