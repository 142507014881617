@import './Common/responsive';

.my-account-card {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    max-height: 530px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .account-card-body {
        .heading {
            color: #1C1C1C;
            font-size: 20px;
            font-weight: 300;
            margin-bottom: 15px;
            letter-spacing: 0.64px;
        }

        .link-text {
            color: #32AEFA;
            cursor: pointer;
            font-size: 16px;
            letter-spacing: 0.4px;

            &:hover {
                text-decoration: underline;
            }
        }

        .app-download-btngroup {
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 32px;

            @include respond-to(sm) {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 12px;
                margin-bottom: 16px;
            }

            .app-download-btn {
                text-transform: uppercase;
                text-align: center;
                text-decoration: none;
            }
        }

        .profile-details {
            .heading {
                color: #1C1C1C;
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 15px;
                letter-spacing: 0.64px;
            }
        }

    }

    .inner-page-footer {
        display: flex;
        flex-direction: row-reverse;
        gap: 8px;
        padding: 12px 16px;
    }

    .img-card {
        display: flex;
        border-radius: 8px;
        flex-direction: column;
        justify-content: center;
        background-color: #F3F5FA;
        padding: 24px 16px 16px 16px;
    }
}

.form-card {
    margin-top: 12px;
}