@import url("./_networkdashboard.scss");
@import url("./_header.scss");
@import url("./_sidebar.scss");
@import url("./_requests.scss");
@import url("./_components.scss");
@import url("./Common/_emptyscreen.scss");
@import url("./Common/_textfield.scss");
@import url("../../components/userLogin/styles.scss");
@import url("./Common/_customtoaster.scss");
@import url("./_dashboard.scss");
@import url("./_documentation.scss");
@import url("./_header.scss");
@import url("./_networksidebar.scss");
@import url("./_sidebar.scss");
@import './Common/responsive';

@import url("./_requests.scss");
@import url("./_components.scss");
@import url("./Common/_customtoaster.scss");
@import url("./Common/_stepper.scss");
@import url("./Common/widget.scss");
/* The following line can be included in a src/App.scss */
@import "~bootstrap/scss/bootstrap";
@import "react-loading-skeleton/dist/skeleton.css";

* {
  font-family: "Poppins";
}

body {
  margin: 0px auto;
}

.background {
  background-color: #19253a;
}

.text-blue {
  color: #3d97cf;
}

.main-layout {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 0px;
  margin: 0px auto;
  overflow: hidden;
  position: relative;

  .wizard-btn {
    position: absolute;
    left: 24px;
    bottom: 24px;
    background-color: #ffffff;
    border-radius: 50px;
    padding: 8px 12px;
    border: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    img {
      margin-right: 8px;
    }

    @include respond-to(sm) {
      display: none;
    }
  }

  .sidebar-menu {
    min-width: 250px;
    transition: ease-out 0.2s;
  }

  .collapsed-menu {
    min-width: 60px;
    transition: ease-in 0.2s;

    .collapse-brand-logo {
      width: 80px;
      padding: 30px 8px;

      img {
        @include respond-to(md) {
          width: 36px;
        }
      }

    }

  }

  .right-container {
    width: calc(100% - 60px);
    background-color: #eaeff1;
    overflow: auto !important;
    padding: 16px 32px;


    @include respond-to(md) {
      width: 100%;
      padding: 12px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .inner-container {
      box-sizing: border-box;
      border-radius: 8px;
    }
  }
}