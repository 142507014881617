@import './Common/responsive';

.requests-container {
  box-sizing: border-box;
  height: 100%;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;

  .request-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: 16px;

    @include respond-to(sm) {
      flex-direction: column-reverse;
      align-items: flex-end;
      gap: 8px;
    }

    .search-bar-box {
      width: 300px;
      z-index: 99;

      .search-bar {
        box-sizing: border-box;
        border: 1px solid #E2E3E5;
        border-radius: 4px;
        font-size: 16px;
        background-repeat: no-repeat;
        background-position: 0;
        background-position-x: 8px;
        background-size: 16px 16px;
        font-weight: 400;
        background-image: url("../images/search.svg");
        padding: 5px 5px 5px 35px;
        transition: width 0.4s ease-in-out;
        margin-bottom: 0;
        width: 100%;
        height: 38px;
        color: #777777;

        &:focus {
          transition: 0.2s ease-in;
          outline-color: #E2E3E5;
          border-color: #E2E3E5;
        }

        &:focus-visible {
          outline-color: #E2E3E5;
          border-color: #E2E3E5;
        }
      }

      [type="search"] {
        outline-color: gray;
        outline-offset: 0px;
      }
    }
  }

  .requests-body {
    height: 550px;
    overflow: auto;
    max-height: 550px;

    /* width */
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 12px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .requests-table {
      width: 100%;
      border-collapse: collapse;
      color: #343330;
      font-size: 14px;
      font-weight: 500;

      thead {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: #EAEFF1;
        z-index: 2;

        tr {
          th {
            font-size: 16px;
            padding: 12px 0px;
            background-color: #eaeff175;
            text-align: left;
            padding-left: 24px;
            color: #1C1C1C;

            &:nth-child(4) {
              text-align: center;
            }
          }
        }
      }

      tbody {
        tr {

          border-bottom: 1px solid #e7e7ed;

          td {
            padding: 12px 0px;
            text-align: left;
            padding-left: 24px;
            color: #777777;
            font-size: 16px;

            .copy-icon {
              cursor: pointer;
              position: relative;
              right: 8px;
            }

            .copy-icon:hover {
              transform: scale(1.2);
              transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
                0.3s box-shadow,
                0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
            }

            .copy-icon:active {
              transform: scale(0.9);
              transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
                0.3s box-shadow,
                0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
            }


            &:nth-child(4) {
              text-align: center;
            }
          }

          .feed-thumb {
            img {
              border-radius: 4px;
            }
          }

          // .ellipsis-text {
          //   display: block;
          //   width: 40%;
          //   overflow: hidden;
          //   text-overflow: ellipsis;
          //   white-space: nowrap;
          // }
        }
      }

      .empty-screen-tr {
        border-bottom: none;
        position: relative;
        top: 80px;
      }
    }
  }
}

@include respond-to(md) {
  .requests-container {
    .requests-body {
      overflow: auto;

      .requests-table {
        width: max-content;

        thead {
          tr {
            th {
              font-size: 12px;
            }
          }
        }

        tbody {
          tr {

            td {
              font-size: 10px;

              .connection-did {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}