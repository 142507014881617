@mixin commonCard {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 12px 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 12px 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
}

.home-body {
  .update-card {
    @include commonCard;
    // height: 100px;
    border-bottom: 5px solid;
    border-color: #0076fc;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .title-1 {
      font-size: 24px;
      font-weight: 700;
    }

    color: #212121;

    img {
      height: 60px;
      width: 60px;
    }

  }

  .health-card {
    border-color: #5cbd99;

    .health-card {
      border-color: #5cbd99;

      .card-right {
        .title-1 {
          color: #5cbd99;
        }

        .card-right {
          .title-1 {
            color: #5cbd99;
          }

          .title-2 {
            font-size: 16px;
            font-weight: 500;
            color: #777777;
          }
        }
      }

      .health-card {
        border-color: #5cbd99;

        .card-right {
          .title-1 {
            color: #5cbd99;
          }

          .title-2 {
            color: #5cbd99;
          }
        }
      }

      .title-2 {
        color: #5cbd99;
      }
    }
  }

  .graph-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @include commonCard;

    .graph-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      @include commonCard;

      .title-1 {
        font-size: 18px;
        font-weight: 500;
        color: #1c1c1c;
      }

      .title-1 {
        font-size: 24px;

        color: #272832;
      }

      .title-1 {
        font-size: 18px;
        font-weight: 500;
        color: #1c1c1c;
      }

      .title-2 {
        font-size: 18px;
        font-weight: 400;
        color: #1c1c1c;
      }

      .title-2 {
        font-size: 20px;
        font-weight: 500;
        color: #605e5c;
      }

      .title-2 {
        font-size: 18px;
        font-weight: 400;
        color: #1c1c1c;
      }

      .credential-type-chart {
        // width: 250px;
        // height: 260px;
        // canvas {
        //     height: 250px;
        // }
      }
    }

    .credential-type-chart {
      // width: 250px;
      // height: 260px;
      // canvas {
      //     height: 250px;
      // }
    }
  }

  .nodes {
    .nodes-card {
      @include commonCard;
      color: #212121;

      .title-2 {
        font-size: 15px;

        color: black;

        .node-title {
          font-weight: 600;
          font-size: 14px;
          color: black;
        }

        .node-subtext {
          font-size: 14px;
          font-weight: 500;
          word-break: break-all;

        }
      }
    }
  }

  .widget {
    .card {
      .card-subtitle {
        font-size: 18px;
        font-weight: 500;
        color: #1e1f1f;
      }
    }
  }

  .identity-card {
    height: 250px;
    @include commonCard;
    padding: 0px;

    .identity-card {
      height: 250px;
      @include commonCard;
      padding: 0px;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #212121;
        border-bottom: 1px solid #e0e0e0;
        padding: 12px 16px;
      }

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #212121;
        border-bottom: 1px solid #e0e0e0;
        padding: 12px 16px;
      }

      .description {
        padding: 12px 16px;
      }
    }

    .description {
      padding: 12px 16px;
    }
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    color: #1e1f1f;
  }
}