@import './Common/responsive';

@mixin commonCard {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 24px;
  max-width: 500px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
}

.welcome-page {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin-top: 50px;
  position: relative;

  h1 {
    font-size: 45px;
    font-weight: 400;
    color: #1C1C1C;

    .brand-name {
      color: #0076FC;

      sup {
        font-size: 18px;
        position: relative;
        top: -30px;
      }
    }
  }


  .description {
    width: 50%;
    text-align: center;
    font-size: 16px;
    color: #777777;

    span {
      sup {
        font-size: 10px;
        position: relative;
        top: -8px;
      }
    }
  }

  @include respond-to(md) {

    h1 {
      font-size: 28px;

      .brand-name {
        sup {
          top: -22px;
        }
      }
    }

    .description {
      width: 100%;
    }
  }

  .welcome-card {
    @include commonCard;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #212121;
    border: 1px solid #E2E3E5;
    gap: 16px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .title {
      text-align: center;
      font-size: 16px;
      color: #777777;
    }

    img {
      height: 42px;
      width: 42px;
    }
  }

}

.join-consortium-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  .consortium-details {
    margin-top: 24px;

    p {
      color: #12263C;
      opacity: 0.7;
      font-size: 16px;
      margin-bottom: 24px;
      letter-spacing: 0.43px;
    }

    img {
      width: 500px;
      height: 200px;
    }

    .view-details-text {
      text-decoration: none;
      color: #009be5;
      font-size: 14px;
      font-weight: 500;
    }

    .choose-region {
      padding: 16px 0px;
      border-top: 1px solid #D4D9E0;


      .nav-tabs {
        border-bottom: none;
        background: #F4F6FB;
        border-radius: 8px;
        padding: 6px;
        width: 100%;
        gap: 4px;
        width: -moz-fit-content;
        width: fit-content;

        .nav-item {
          .nav-link {
            text-transform: uppercase;
            color: #4398CD;
            border: none;
            border-radius: 8px;

            &:hover {}
          }

          .nav-link.active {
            color: #ffffff;
            background-color: #4398CD;
          }
        }
      }

      .tab-content {
        .choose-region-list {
          ul {
            list-style: none;
            padding: 0px;
            margin: 0px;
            display: grid;
            grid-template-columns: auto auto auto;
            grid-gap: 10px;

            @include respond-to(md) {
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 12px;
            }

            @include respond-to(sm) {
              grid-template-columns: repeat(1, 1fr);
              grid-gap: 12px;
            }


            li {
              position: relative;
              padding: 16px;
              background-color: #F4F6FB;
              width: 100%;
              border-radius: 4px;
              transition: all 0.2s ease-in-out;
              cursor: pointer;

              p {
                font-size: 14px;
                color: #272833;
                font-weight: 600;
                margin-bottom: 0px;
                display: flex;
                align-items: center;

                .dsm-radio-btn {
                  margin-top: 1px;

                  &:disabled {
                    cursor: not-allowed;
                  }
                }
              }

              small {
                font-size: 12px;
                font-weight: 500;
                color: #6B6C7E;
                padding-left: 26px;
              }
            }
          }

          .disabled-region-item {
            cursor: not-allowed;
          }

          .selected-region-item {
            width: -moz-fit-content;
            width: fit-content;
          }
        }
      }
    }
  }
}

.consortium-list-page {
  .sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-to(sm) {
      flex-direction: column;
      align-items: end;
    }

    .title {
      color: #1C1C1C;
      font-size: 20px;
      font-weight: 500;
    }

    .action-items {
      display: flex;
      align-items: center;
      gap: 8px;

      @include respond-to(sm) {
        flex-direction: column;
        align-items: end;
      }

      .select-item {
        width: 250px;
        max-width: 500px;
      }

      .action-icon {
        border-color: #E2E3E5;
      }
    }
  }

  .body-content {
    margin-top: 24px;

    .loader {
      margin-top: 100px;
      margin-left: 100px;
    }

    .consortium-card {
      width: 340px;
      border-radius: 4px;
      background-color: #ffffff;
      border-top: 5px solid #0076FC;
      cursor: pointer;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .card-header {
        padding: 12px 16px 0px 16px;
        display: flex;
        flex-direction: row-reverse;
      }

      .card-body {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 12px 16px;

        p {
          font-size: 16px;
          color: #1C1C1C;
          font-weight: 500;
          text-transform: capitalize;
        }
      }

      .card-footer {
        padding: 16px;
        background-color: #F5F7FB;
        color: #1C1C1C;
        font-size: 16px;
        border-radius: 0px 0px 4px 4px;
        font-weight: 500;

        span {
          color: #777777;
        }
      }
    }
  }
}

.qr-code-button-group {
  @include respond-to(sm) {
    width: 100% !important;
  }
}