

.login-container{
  .login-box {
    width: 360px;
  }
  
  .logo {
    text-align: center;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    color: #ffffff;
  }
  
  .login-form form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    color: #ccc;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #425371;
    border-radius: 4px;
    color: white;
  
    background-color: #19253a;
  }
  
  .form-group input::placeholder {
    color: #ccc;
  }
  
  button {
    background-color: #0076fc;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .box {
    width: 100%;
    padding: 10px;
    margin: 2px;
    border: 1px solid #425371;
    border-radius: 4px;
    color: white;
    text-align: center;
  
    background-color: #19253a;
  
  }

}



