.empty-screen-wraper {
    display: flex;
    align-items: center;
    justify-content: baseline;
    flex-direction: column;
    gap: 24px;

    .content {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        .heading {
            color: #272833;
            letter-spacing: 0.25px;
            margin: unset;
        }

        .sub-heading {
            letter-spacing: 0.25px;
            color: grey;
            margin: unset;
            text-align: center;
        }
    }
}