.sidebar-menu {
  position: relative;
  height: 100vh;
  background-color: #323448;

  .brand-logo {
    padding: 40px 0px;
    text-align: center;
    margin-bottom: 0px;

    img {
      width: 200px;
    }
  }

  .nav-menu {
    padding: 0px;

    .nav-item {
      list-style: none;

      a {
        display: block;
        padding: 12px 16px;
        transition: ease-in-out 0.2s;
        color: #5F687D;
        text-decoration: none;
        font-weight: 500;
        border-left: 2px solid transparent;

        &:hover {
          background-color: #343F57;
        }

        span {
          position: relative;
          bottom: 6px;
          left: 16px;
        }
      }

      a.active {
        color: #4EBEF2;
        background-color: #343F57;
        border-left: 2px solid;
      }

      .dashboard {
        &::before {
          content: url("../images/squares-four.svg");
        }
      }

      .dashboard.active {
        &::before {
          content: url("../images/squares-four-duotone.svg");
        }
      }

      .issuer {
        &::before {
          content: url("../images/user-circle.svg");
        }
      }


      .issuer.active {
        &::before {
          content: url("../images/user-circle-duotone.svg");
        }
      }

      .verifier {
        &::before {
          content: url("../images/user-circle-check.svg");
        }
      }

      .verifier.active {
        &::before {
          content: url("../images/user-circle-check-duotone.svg");
        }
      }

      .blockchain-ledger {
        &::before {
          content: url("../images/bounding-box.svg");
        }
      }

      .blockchain-ledger.active {
        &::before {
          content: url("../images/bounding-box-duotone.svg");
        }
      }

      .news-feed {
        &::before {
          content: url("../images/article.svg");
        }
      }

      .news-feed.active {
        &::before {
          content: url("../images/article-duotone.svg");
        }
      }

      img {
        height: 24px;
        width: 24px;
        color: #60687d;
      }
    }

    .side-menu-heading {
      color: #999AA4;
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      padding: 12px 16px;
    }
  }
}